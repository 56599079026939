import { UpdatePaymentStatementEventType } from '@hooks/payment';
import { User } from '@models/auth';
import { Membership, PagenatedResponse, PhoneValidatorResponse } from '@models/common';
import { Notice, NoticesResponse } from '@models/notices';
import { CreatePaymentStatementsBody, PaymentStatement, TossPaymentsConfirmResponseBody } from '@models/purchase';
import { RefundLog } from '@models/refundLog';
import { UrlPreviewData, UserContent } from '@models/user-contents';
import { apiClient } from '.';

export const requestPhoneValidate = async (phoneNumber: string) => {
  return apiClient.post<PhoneValidatorResponse>(`/app/v1/phone`, { phoneNumber });
};

export const validatePhone = async (data: { phoneNumber: string; codeNumber: number }) => {
  return apiClient.post<{ members?: Partial<User>[]; resultMessage: string }>(`/app/v1/phone/validation`, {
    data,
  });
};

export const getInprogressMembershipJoin = async () => {
  return apiClient.get<Membership>(`app/v1/memberships/inprogress`);
};

export const getMembership = async (alias: string) => {
  return apiClient.get<Membership>(`app/v1/memberships/${alias}`);
};

export const getEvent = async (alias: string) => {
  return apiClient.get(`app/v1/events/${alias}`);
};

export const getJoinPromotion = async (params: { ec?: string; rc?: string }) => {
  return apiClient.get('app/v1/join-promotion', { params: params });
};

export const getJoinPromotionCode = async () => {
  return apiClient.get('app/v1/join-promotion/code');
};

export const getNotices = async () => {
  return apiClient.get<NoticesResponse>('app/v1/notices');
};

export const getNotice = async (noticeId: string) => {
  return apiClient.get<Notice>(`app/v1/notices/${noticeId}`);
};

export const getReviews = async (page: number, size: number) => {
  return apiClient.get<PagenatedResponse<UserContent>>('app/v1/community/reviews', {
    params: { page, size },
  });
};

export const getBestReviews = async () => {
  return apiClient.get<UserContent[]>('app/v1/community/reviews/best');
};

export const createPaymentStatement = async (data: CreatePaymentStatementsBody) => {
  return apiClient.post<PaymentStatement>(`/app/v1/payment-statement`, {
    data,
  });
};

export const updatePaymentStatement = async (data: {
  data: PaymentStatement;
  event: UpdatePaymentStatementEventType;
}) => {
  return apiClient.put<PaymentStatement>(`/app/v1/payment-statement/${data.data.uuid}`, {
    data,
  });
};

export const getPaymentStatement = async (paymentStatementUuid: string) => {
  return apiClient.get<PaymentStatement>(`/app/v1/payment-statement/${paymentStatementUuid}`);
};

export const updateTossPayments = async (data: TossPaymentsConfirmResponseBody) => {
  return apiClient.post<PaymentStatement>(`/app/v1/payment/callback/toss`, data);
};

export const getRefundLogs = async (paymentStatementId: string) => {
  return apiClient.get<RefundLog[]>(`/app/v1/refund-logs/${paymentStatementId}`);
};

export const getUrlPreview = async (url: string) => {
  return apiClient.get<UrlPreviewData>(`/preview`, { params: { url } });
};

export const cancelPayment = async (paymentStatementId: string) => {
  return apiClient.post<PaymentStatement>(`/app/v1/payment-statement/${paymentStatementId}/cancel`);
};
