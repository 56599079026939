import { UserContent } from '@models/user-contents';
import { apiClient } from '.';

export const getUserContent = async (userContentId: string) => {
  return apiClient.get<UserContent>(`/app/v1/user-contents/${userContentId}`);
};

export const createUserContent = async (formData: FormData) => {
  return apiClient.post<UserContent>(`/app/v1/user-contents`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const createMyFavorites = async (userContentId: string) => {
  return apiClient.post(`/app/v1/user-contents/${userContentId}/favorite`);
};

export const deleteMyFavorites = async (userContentId: string) => {
  return apiClient.delete(`/app/v1/user-contents/${userContentId}/favorite`);
};
