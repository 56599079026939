import { Roles } from '@consts/role';
import { Children } from '@models/user-contents';
import { useAppLoginModal } from '@stores/loginModalStore';
import { useAppNavigation } from './appNavigation';
import { useAuthentication } from './authentication';
import { APP_BASE_PATH } from '@variables';
import { useAppSocket } from './appSocket';
import { openLink } from '@utils/common';

export interface LegacyDeeplinkAction {
  direction?: string;
  direction2_name?: string;
  direction1?: string;
  direction2?: string;
  direction3?: string;
  searchText?: string;
  classId?: string;
  classReportId?: string;
  classInvoiceId?: string;
  tutorId?: string;
  isTutor?: boolean;
  tutorName?: string;
  className?: string;
  classImageUrl?: string;
  parentId?: string;
  parentName?: string;
  childrenName?: string;
  children?: Children;
  isChatToReport?: boolean;
  isClassCardModal?: boolean;
  scheduleTimes?: number;
  scheduleStartDate?: Date;
}

export interface DeeplinkActionPayload {
  classId?: string;
  partnerId?: string;
  tutorId?: string;
  parentId?: string;
  chatRoomId?: string;
  collectionId?: string;
  brandId?: string;
  productId?: string;
  userContentId?: string;
  title?: string;
  keyword?: string;
  url?: string;
  noticeId?: string;
  tab?: string;
  write?: boolean;
}
export interface DeeplinkAction {
  action?: DeepLinkActions;
  payload?: DeeplinkActionPayload;
}

export enum DeepLinkActions {
  GO_TO_MAIN = 'go_to_main',
  GO_TO_COMMUNITY = 'go_to_community',
  GO_TO_NOTICE = 'go_to_notice',
  GO_TO_NEWS = 'go_to_news',
  GO_TO_CLASS_DETAIL = 'go_to_class_detail',
  OPEN_LINK = 'open_link',
  GO_TO_COUPON = 'go_to_coupon',
  GO_TO_COLLECTION = 'go_to_collection',
  GO_TO_PRODUCT = 'go_to_product',
  GO_TO_CLASS_COLLECTION = 'go_to_class_collection',
  GO_TO_PICK = 'go_to_pick',
  GO_TO_COMMERCE_COLLECTION = 'go_to_commerce_collection',
  GO_TO_THEME_COLLECTION = 'go_to_theme_collection',
  GO_TO_COMMERCE_DETAIL = 'go_to_commerce_detail',
  OPEN_APP_LOGIN_MODAL = 'open_app_login_modal',
  SEARCH_KEYWORD = 'search_keyword',
  GO_TO_CHAT = 'go_to_chat',
  GO_TO_CHAT_LIST = 'go_to_chat_list',
  GO_TO_REWARD = 'go_to_reward',
  GO_TO_MY_PAGE = 'go_to_my_page',
  GO_TO_PURCHASE = 'go_to_purchase',
  GO_TO_LOUNGE = 'go_to_lounge',
  GO_TO_LOUNGE_PROFILE = 'go_to_lounge_profile',
  GO_TO_FAVORITES = 'go_to_favorites',
  GO_TO_PARTNER = 'go_to_partner',
}

const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i) == null ? false : true;
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null ? false : true;
  },
  any: function () {
    return isMobile.Android() || isMobile.iOS();
  },
};

export const useDeeplink = () => {
  const appSocket = useAppSocket();
  const appLoginModal = useAppLoginModal();
  const appNavigation = useAppNavigation();
  const { authStore } = useAuthentication({ disableLoginRefresh: true });
  const isLogin = !!authStore.user;
  const isTutor = authStore.user?.role === Roles.TUTOR;
  const user = authStore.user;

  const linkTo = (
    item: DeeplinkAction,
    options?: {
      state?: any;
      source?: string;
      keyword?: string;
      productId?: string;
      collectionId?: string;
      tutorId?: string;
      partnerId?: string;
      link?: string;
      notificationId?: string;
      index?: number;
      page?: number;
    },
  ) => {
    let isSendClickEvent = false;
    if (item.action === DeepLinkActions.GO_TO_COMMUNITY) {
      appNavigation.to(`${APP_BASE_PATH}my-page/notice`, {
        state: {
          userId: user?.id,
          userName: user?.profile?.name || '',
          reviewIndex: 0,
          reviewSkip: 0,
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_NOTICE) {
      const noticeId = item?.payload?.noticeId || '';
      appNavigation.to(`${APP_BASE_PATH}notice/${noticeId}`, {
        state: {
          noticeId: noticeId,
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_REWARD) {
      appNavigation.to(`${APP_BASE_PATH}my-page/reward`);
    } else if (item.action === DeepLinkActions.GO_TO_NEWS) {
      appNavigation.to(`${APP_BASE_PATH}my-page/notice`, {
        state: {
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_CLASS_DETAIL) {
      const productId = item.payload?.classId || item.payload?.productId;
      appNavigation.to(`${APP_BASE_PATH}product/${productId}`, {
        state: {
          productId: productId,
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.OPEN_LINK) {
      const link = item?.payload?.url || '';
      openLink(link);
    } else if (item.action === DeepLinkActions.GO_TO_COUPON) {
      if (isLogin) {
        if (isTutor) {
          appNavigation.to(`${APP_BASE_PATH}my-page/coupon`, {
            state: {
              screen: '마이페이지',
              initial: false,
              params: {
                isTutorCouponDisable: true,
              },
              ...options?.state,
            },
          });
        } else {
          appNavigation.to(`${APP_BASE_PATH}my-page/coupon`, {
            state: {
              initial: false,
              ...options?.state,
            },
          });
        }
      } else {
        appNavigation.to(`${APP_BASE_PATH}my-page/coupon`, {
          state: {
            initial: false,
            ...options?.state,
          },
        });
      }
    } else if (item.action === DeepLinkActions.GO_TO_CLASS_COLLECTION) {
      appNavigation.to(`${APP_BASE_PATH}collection`, {
        state: {
          collectionId: item.payload?.collectionId,
          type: 'class',
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_COLLECTION) {
      const collectionId = item.payload?.collectionId;
      appNavigation.to(`${APP_BASE_PATH}collection`, {
        state: {
          collectionId: collectionId,
          ...options?.state,
        },
      });
      isSendClickEvent = true;
      appSocket.sendEvent({
        type: 'click',
        data: {
          userId: authStore.user?.id,
          path: document.location.pathname,
          actionData: {
            from: document.location.pathname,
            collectionId: collectionId,
            id: options?.source,
            actionType: 'click-collection',
            options: {
              keyword: options?.keyword,
              collectionId: options?.collectionId,
              tutorId: options?.tutorId,
              link: options?.link,
              notificationId: options?.notificationId,
              index: options?.index,
              page: options?.page,
              productId: options?.productId,
            },
          },
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_COMMERCE_COLLECTION) {
      appNavigation.to(`${APP_BASE_PATH}collection`, {
        state: {
          collectionId: item.payload?.collectionId,
          type: 'commerce',
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_PRODUCT) {
      const productId = item.payload?.productId;
      appNavigation.to(`${APP_BASE_PATH}product/${productId}`, {
        state: {
          productId: productId,
          ...options?.state,
        },
      });
      isSendClickEvent = true;
      appSocket.sendEvent({
        type: 'click',
        data: {
          userId: authStore.user?.id,
          path: document.location.pathname,
          actionData: {
            from: document.location.pathname,
            productId: productId,
            id: options?.source,
            actionType: 'click-product',
            options: {
              keyword: options?.keyword,
              collectionId: options?.collectionId,
              tutorId: options?.tutorId,
              link: options?.link,
              notificationId: options?.notificationId,
              index: options?.index,
              page: options?.page,
              productId: options?.productId,
            },
          },
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_PICK) {
      appNavigation.to(`${APP_BASE_PATH}commerce`, {
        state: {
          screen: '한정판매',
          initial: true,
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_THEME_COLLECTION) {
      const collectionId = item.payload?.collectionId;
      appNavigation.to(`${APP_BASE_PATH}theme/collection`, {
        state: {
          collectionId: collectionId,
          ...options?.state,
        },
      });
      isSendClickEvent = true;
      appSocket.sendEvent({
        type: 'click',
        data: {
          userId: authStore.user?.id,
          path: document.location.pathname,
          actionData: {
            from: document.location.pathname,
            collectionId: collectionId,
            id: options?.source,
            actionType: 'click-theme-collection',
            options: {
              keyword: options?.keyword,
              collectionId: options?.collectionId,
              tutorId: options?.tutorId,
              link: options?.link,
              notificationId: options?.notificationId,
              index: options?.index,
              page: options?.page,
              productId: options?.productId,
            },
          },
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_COMMERCE_DETAIL) {
      const collectionId = item.payload?.collectionId;
      const productId = item.payload?.productId;

      appNavigation.to(`${APP_BASE_PATH}product/${productId}`, {
        state: {
          collectionId: collectionId,
          productId: productId,
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.SEARCH_KEYWORD) {
      appNavigation.to(`${APP_BASE_PATH}search`, {
        state: {
          keyword: item.payload?.keyword,
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.OPEN_APP_LOGIN_MODAL) {
      appLoginModal.open();
    } else if (item.action === DeepLinkActions.GO_TO_CHAT) {
      appNavigation.to(`${APP_BASE_PATH}chat`, {
        state: {
          productId: item.payload?.productId,
          tutorId: item.payload?.tutorId,
          parentId: item.payload?.parentId,
          ...options?.state,
        },
      });
    } else if (item.action === DeepLinkActions.GO_TO_CHAT_LIST) {
      appNavigation.to(`${APP_BASE_PATH}chats`, { state: { ...options?.state } });
    } else if (item.action === DeepLinkActions.GO_TO_MAIN) {
      appNavigation.to(`${APP_BASE_PATH}`, { state: { ...options?.state } });
    } else if (item.action === DeepLinkActions.GO_TO_MY_PAGE) {
      appNavigation.to(`${APP_BASE_PATH}my-page`, { state: { ...options?.state } });
    } else if (item.action === DeepLinkActions.GO_TO_PURCHASE) {
      appNavigation.to(`${APP_BASE_PATH}my-page/purchase`, { state: { ...options?.state } });
    } else if (item.action === DeepLinkActions.GO_TO_LOUNGE) {
      const userContentId = item.payload?.userContentId;
      const tab = item.payload?.tab;
      const write = item.payload?.write;
      if (userContentId && userContentId.length > 0) {
        appNavigation.to(`${APP_BASE_PATH}lounge/${userContentId}`, { state: { ...options?.state } });
      } else {
        appNavigation.to(`${APP_BASE_PATH}lounge`, { state: { tab: tab, write: write, ...options?.state } });
      }
    } else if (item.action === DeepLinkActions.GO_TO_LOUNGE_PROFILE) {
      appNavigation.to(`${APP_BASE_PATH}lounge/profile`, { state: { ...options?.state } });
    } else if (item.action === DeepLinkActions.GO_TO_FAVORITES) {
      const tab = item.payload?.tab;
      appNavigation.to(`${APP_BASE_PATH}my-page/favorite/${tab}`);
    } else if (item.action === DeepLinkActions.GO_TO_PARTNER) {
      const partnerId = item.payload?.partnerId;
      appNavigation.to(`${APP_BASE_PATH}partner/${partnerId}`);
    }

    if (!isSendClickEvent) {
      appSocket.sendEvent({
        type: 'click',
        data: {
          userId: authStore.user?.id,
          path: document.location.pathname,
          actionData: {
            from: document.location.pathname,
            id: options?.source,
            actionType: item.action,
            payload: item.payload,
            options: {
              keyword: options?.keyword,
              collectionId: options?.collectionId,
              tutorId: options?.tutorId,
              link: options?.link,
              notificationId: options?.notificationId,
              index: options?.index,
              page: options?.page,
              productId: options?.productId,
            },
          },
        },
      });
    }
  };

  return { linkTo };
};
