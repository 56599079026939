import { PagenatedResponse } from '@models/common';
import { UserContent, UserContentsSearchQuery } from '@models/user-contents';
import { getNonDulplicatedArrayById } from '@utils/common';
import { create } from 'zustand';

export const PAGE_SIZE = 20;

interface LoungeContentsContainer {
  contents: UserContent[];
  page: number;
  size: number;
  total: number;
  hasNext: boolean;
}

interface LoungeContentsStoreProps {
  searchResultContainer: LoungeContentsContainer;
  searchQuery: UserContentsSearchQuery;
  searchKeyword?: string;
  setSearchKeyword: (keyword: string) => void;
  updateSearchQuery: (query: { [key: string]: any }) => void;
  resetSearchQuery: () => void;
  setLoungeContentsContainer: (result: PagenatedResponse<UserContent>) => void;
  increaseFavoriteCount: (uesrContentId: string) => void;
  decreaseFavoriteCount: (uesrContentId: string) => void;
  clear: () => void;
}

export const useLoungeContentsStore = create<LoungeContentsStoreProps>((set, get) => ({
  clear: () => {
    set({
      searchResultContainer: {
        contents: [] as UserContent[],
        total: 0,
        page: 0,
        size: 0,
        hasNext: false,
      },
    });
  },
  increaseFavoriteCount: (userContentId: string) => {
    set((state) => {
      const newContents = [...state.searchResultContainer.contents].map((content) => {
        if (content._id === userContentId) {
          return { ...content, like: (content.like || 0) + 1 };
        }
        return content;
      });

      return { ...state, searchResultContainer: { ...state.searchResultContainer, contents: newContents } };
    });
  },
  decreaseFavoriteCount: (userContentId: string) => {
    set((state) => {
      const newContents = [...state.searchResultContainer.contents].map((content) => {
        if (content._id === userContentId) {
          return { ...content, like: (content.like || 1) - 1 };
        }
        return content;
      });

      return { ...state, searchResultContainer: { ...state.searchResultContainer, contents: newContents } };
    });
  },
  searchResultContainer: {
    contents: [] as UserContent[],
    total: 0,
    page: 0,
    size: 0,
    hasNext: false,
  },
  searchQuery: { page: 0, size: PAGE_SIZE, board: 'all', category: 'all' },
  searchKeyword: undefined,
  setLoungeContentsContainer: (result) => {
    set((state) => {
      let contents = getNonDulplicatedArrayById([...state.searchResultContainer.contents], result?.contents || []);
      if (result?.page === 0) {
        contents = [...(result?.contents || [])];
      }
      const newValue = { ...state.searchResultContainer, ...result, contents: contents };
      return { ...state, searchResultContainer: newValue };
    });
  },
  setSearchKeyword: (keyword: string) => {
    set((state) => {
      return {
        ...state,
        searchKeyword: keyword,
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  updateSearchQuery: (query: { [key: string]: any }) => {
    set((state) => {
      let page = query.page;
      if (query.keyword) {
        page = 0;
      }

      return {
        ...state,
        searchQuery: { ...state.searchQuery, ...query, page },
        searchResultContainer: { ...state.searchResultContainer, size: 0 },
      };
    });
  },
  resetSearchQuery: () => {
    set({ searchQuery: { page: 0, size: PAGE_SIZE, board: 'all', category: 'all' } });
  },
}));
