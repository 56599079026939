import React from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as BottomSheetCloseIcon } from '@assets/images/app/x-nutral90.svg';
import { ReactComponent as BottomSheetBackIcon } from '@assets/images/app/arrow-left.svg';
export interface BottomSheetProps {
  open?: boolean;
  height?: number;
  onClose?: (e: React.MouseEvent<any, MouseEvent>) => void;
  onBack?: (e: React.MouseEvent<any, MouseEvent>) => void;
  children?: any;
  title?: any;
  hideBackButton?: boolean;
  style?: any;
}

export const BottomSheet: React.FC<BottomSheetProps> = (props) => {
  const showAnimation = {
    opacity: 1,
    display: 'block',
  };

  const hideAnaimation = {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  };

  const show = props.open;
  const height = props.height || 400;

  return (
    <div id="bottom-sheet" style={{ height: show ? 'auto' : 0, overflow: 'hidden' }}>
      <motion.div
        animate={show ? showAnimation : hideAnaimation}
        transition={{ duration: 0.5 }}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          props.onClose?.(e);
        }}
        style={{
          opacity: 0,
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          position: 'absolute',
          backgroundColor: 'rgba(0,0,0,0.4)',
          zIndex: show ? 99999 : -10,
        }}></motion.div>
      <motion.div
        animate={{ bottom: show ? 0 : -height, opacity: show ? 1 : 0 }}
        transition={{ delay: 0.2, duration: 0.3 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{
          opacity: 0,
          position: 'absolute',
          height: height,
          backgroundColor: 'white',
          width: '100%',
          zIndex: show ? 999999 : -10,
          borderRadius: '20px 20px 0 0',
          overflow: 'hidden',
          ...props?.style,
        }}>
        {props.title && (
          <div
            style={{
              height: '68px',
              color: 'var(--Neutral-100, #242424)',
              fontFamily: 'Pretendard',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 700,
              padding: '0 20px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!props.hideBackButton && (
                <button
                  className="link-button"
                  style={{ marginRight: '16px' }}
                  onClick={(e) => {
                    props.onBack?.(e);
                  }}>
                  <BottomSheetBackIcon />
                </button>
              )}
              <div>{props.title}</div>
            </div>
            <button
              className="link-button"
              onClick={(e) => {
                props.onClose?.(e);
              }}>
              <BottomSheetCloseIcon />
            </button>
          </div>
        )}
        {props.children}
      </motion.div>
    </div>
  );
};
export default BottomSheet;
