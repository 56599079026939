import { retryLazy } from '@utils/lazyLoad';
export const AuthNaver = retryLazy(() => import('./auth/AuthNaver'));
export const AuthKakao = retryLazy(() => import('./auth/AuthKakao'));
export const AuthApple = retryLazy(() => import('./auth/AuthApple'));

// export const LandingPage = retryLazy(() => import('./landing/LandingPage'));
export const PrivacyPage = retryLazy(() => import('./landing/PrivacyPage'));
export const TermsPage = retryLazy(() => import('./landing/TermsPage'));

export const TutorLandingPage = retryLazy(() => import('./landing/TutorLandingPage'));
export const TutorLoginPage = retryLazy(() => import('./tutor/TutorLoginPage'));
export const TutorDashboardPage = retryLazy(() => import('./tutor/TutorDashboardPage'));
export const TutorClassSchedulePage = retryLazy(() => import('./tutor/TutorClassSchedulePage'));

export const TutorProfileEditPage = retryLazy(() => import('./tutor/TutorProfileEditPage'));
export const TutorAccountPage = retryLazy(() => import('./tutor/TutorAccountPage'));

export const MainFeedPage = retryLazy(() => import('./app/MainFeedPage'));
export const SearchPage = retryLazy(() => import('./app/SearchPage'));
export const ChatListPage = retryLazy(() => import('./app/ChatListPage'));
export const CurationPage = retryLazy(() => import('./app/CurationPage'));
export const EventsPage = retryLazy(() => import('./app/EventsPage'));
export const MyPage = retryLazy(() => import('./app/MyPage'));
export const JoinPage = retryLazy(() => import('./app/JoinPage'));
export const JoinReferralPage = retryLazy(() => import('./app/JoinReferralPage'));
export const JoinMembershipPage = retryLazy(() => import('./app/JoinMembershipPage'));
export const LoungePage = retryLazy(() => import('./app/LoungePage'));

export const LoungeDetailPage = retryLazy(() => import('./app/sub-pages/LoungeDetailPage'));
export const LoungeProfilePage = retryLazy(() => import('./app/sub-pages/LoungeProfilePage'));
export const AppEmailLoginPage = retryLazy(() => import('./app/sub-pages/AppEmailLoginPage'));
export const AccountSettingsPage = retryLazy(() => import('./app/sub-pages/AccountSettingsPage'));
export const CollectionMainPage = retryLazy(() => import('./app/sub-pages/CollectionMainPage'));
export const ThemeCollectionMainPage = retryLazy(() => import('./app/sub-pages/ThemeCollectionMainPage'));
export const TutorInfoPage = retryLazy(() => import('./app/sub-pages/TutorInfoPage'));
export const PartnerProfilePage = retryLazy(() => import('./app/sub-pages/PartnerProfilePage'));
export const ChatPage = retryLazy(() => import('./app/sub-pages/ChatPage'));
export const SettlementsPage = retryLazy(() => import('./app/sub-pages/SettlementsPage'));
export const FavoritePage = retryLazy(() => import('./app/sub-pages/FavoritePage'));
export const AppTutorProfileEditPage = retryLazy(() => import('./app/sub-pages/AppTutorProfileEditPage'));
export const AppUserProfileEditPage = retryLazy(() => import('./app/sub-pages/AppUserProfileEditPage'));
export const EditPasswordPage = retryLazy(() => import('./app/sub-pages/EditPasswordPage'));
export const EditPhoneNumberPage = retryLazy(() => import('./app/sub-pages/EditPhoneNumberPage'));
export const EditAddressPage = retryLazy(() => import('./app/sub-pages/EditAddressPage'));
export const EditCategoryPage = retryLazy(() => import('./app/sub-pages/EditCategoryPage'));
export const EditAccountPage = retryLazy(() => import('./app/sub-pages/EditAccountPage'));
export const EditNamePage = retryLazy(() => import('./app/sub-pages/EditNamePage'));
export const EditChildPage = retryLazy(() => import('./app/sub-pages/EditChildPage'));
export const SchedulePage = retryLazy(() => import('./app/sub-pages/SchedulePage'));
export const ClassManagePage = retryLazy(() => import('./app/sub-pages/ClassManagePage'));
export const ProductPage = retryLazy(() => import('./app/sub-pages/ProductPage'));
export const PurchasePage = retryLazy(() => import('./app/sub-pages/PurchasePage'));
export const PurchaseDetailPage = retryLazy(() => import('./app/sub-pages/PurchaseDetailPage'));
export const PurchaseRefundPage = retryLazy(() => import('./app/sub-pages/PurchaseRefundPage'));
export const InvoiceManagePage = retryLazy(() => import('./app/sub-pages/InvoiceManagePage'));
export const UseCouponPage = retryLazy(() => import('./app/sub-pages/UseCouponPage'));
export const RewardPage = retryLazy(() => import('./app/sub-pages/RewardPage'));
export const UserPointPage = retryLazy(() => import('./app/sub-pages/UserPointPage'));
export const NoticePage = retryLazy(() => import('./app/sub-pages/NoticePage'));
export const PolicyPage = retryLazy(() => import('./app/sub-pages/PolicyPage'));
export const NoticeDetailPage = retryLazy(() => import('./app/sub-pages/NoticeDetailPage'));
export const ClassReportEditPage = retryLazy(() => import('./app/sub-pages/ClassReportEditPage'));
export const ClassReportViewPage = retryLazy(() => import('./app/sub-pages/ClassReportViewPage'));
export const AlarmPage = retryLazy(() => import('./app/sub-pages/AlarmPage'));

export const SellerRootPage = retryLazy(() => import('./seller/SellerRootPage'));
export const SellerLoginPage = retryLazy(() => import('./seller/SellerLoginPage'));
export const SellerPaymentHistoryPage = retryLazy(() => import('./seller/SellerPaymentHistoryPage'));
export const SellerNaverOrdersPage = retryLazy(() => import('./seller/SellerNaverOrdersPage'));
export const SellerNoticesPage = retryLazy(() => import('./seller/SellerNoticesPage'));

export const TutorClassListPage = retryLazy(() => import('./tutor/TutorClassListPage'));
export const TutorClassEditPage = retryLazy(() => import('./tutor/TutorClassEditPage'));
export const TutorClassViewPage = retryLazy(() => import('./tutor/TutorClassViewPage'));
export const TutorPayoutsPage = retryLazy(() => import('./tutor/TutorPayoutsPage'));
export const TutorContractPage = retryLazy(() => import('./tutor/TutorContractPage'));
