import { ForbiddenPage } from '@pages/Forbidden';
import { NotFoundPage } from '@pages/NotFound';
import RedirectToHome from '@pages/RedirectToHome';
import EventsPage from '@pages/app/EventsPage';
import AppSettingsPage from '@pages/app/sub-pages/AppSettingsPage';
import { APP_BASE_PATH } from '@variables';
import {
  AccountSettingsPage,
  AlarmPage,
  AppEmailLoginPage,
  AppTutorProfileEditPage,
  AppUserProfileEditPage,
  AuthApple,
  AuthKakao,
  AuthNaver,
  ChatListPage,
  ChatPage,
  ClassManagePage,
  ClassReportEditPage,
  ClassReportViewPage,
  CollectionMainPage,
  CurationPage,
  EditAccountPage,
  EditAddressPage,
  EditCategoryPage,
  EditChildPage,
  EditNamePage,
  EditPasswordPage,
  EditPhoneNumberPage,
  FavoritePage,
  InvoiceManagePage,
  JoinMembershipPage,
  JoinPage,
  JoinReferralPage,
  LoungeDetailPage,
  LoungePage,
  LoungeProfilePage,
  MainFeedPage,
  MyPage,
  NoticeDetailPage,
  NoticePage,
  PartnerProfilePage,
  PolicyPage,
  PrivacyPage,
  ProductPage,
  PurchaseDetailPage,
  PurchasePage,
  PurchaseRefundPage,
  RewardPage,
  SchedulePage,
  SearchPage,
  SellerLoginPage,
  SellerNaverOrdersPage,
  SellerNoticesPage,
  SellerPaymentHistoryPage,
  SellerRootPage,
  SettlementsPage,
  TermsPage,
  ThemeCollectionMainPage,
  TutorAccountPage,
  TutorClassEditPage,
  TutorClassListPage,
  TutorClassSchedulePage,
  TutorClassViewPage,
  TutorContractPage,
  TutorDashboardPage,
  TutorInfoPage,
  TutorLandingPage,
  TutorLoginPage,
  TutorPayoutsPage,
  TutorProfileEditPage,
  UseCouponPage,
  UserPointPage,
} from './pages';

const routes = [
  // Home
  // { path: '/', element: <LandingPage /> },
  { path: '/terms', element: <TermsPage /> },
  { path: '/privacy', element: <PrivacyPage /> },

  // Seller
  { path: '/seller', element: <SellerRootPage /> },
  { path: '/seller/signin', element: <SellerRootPage /> },
  { path: '/seller/login', element: <SellerLoginPage /> },
  { path: '/seller/dashboard', element: <SellerRootPage /> },
  { path: '/seller/platform-orders', element: <SellerPaymentHistoryPage /> },
  { path: '/seller/naver-orders', element: <SellerNaverOrdersPage /> },
  { path: '/seller/notices', element: <SellerNoticesPage /> },

  // Tutor
  { path: '/tutor', element: <TutorLandingPage /> },
  { path: '/tutor/login', element: <TutorLoginPage /> },

  { path: '/tutor/dashboard', element: <TutorDashboardPage /> },
  { path: '/tutor/products/schedule', element: <TutorClassSchedulePage /> },
  { path: '/tutor/products', element: <TutorClassListPage /> },
  { path: '/tutor/products/:productId/edit', element: <TutorClassEditPage /> },
  { path: '/tutor/products/edit/new', element: <TutorClassEditPage /> },
  { path: '/tutor/products/:productId/view', element: <TutorClassViewPage /> },
  { path: '/tutor/payslip', element: <TutorPayoutsPage /> },
  { path: '/tutor/contract', element: <TutorContractPage /> },
  { path: '/tutor/profile', element: <TutorProfileEditPage /> },
  { path: '/tutor/account', element: <TutorAccountPage /> },

  // Auth
  { path: '/auth/kakao', element: <AuthKakao /> },
  { path: '/auth/naver', element: <AuthNaver /> },
  { path: '/auth/apple', element: <AuthApple /> },

  // App
  { path: `/`, element: <MainFeedPage /> },
  { path: `${APP_BASE_PATH}search`, element: <SearchPage /> },
  { path: `${APP_BASE_PATH}chats`, element: <ChatListPage /> },
  { path: `${APP_BASE_PATH}lounge`, element: <LoungePage /> },
  { path: `${APP_BASE_PATH}lounge/:userContentId`, element: <LoungeDetailPage /> },
  { path: `${APP_BASE_PATH}lounge/profile`, element: <LoungeProfilePage /> },
  { path: `${APP_BASE_PATH}commerce`, element: <CurationPage /> },
  { path: `${APP_BASE_PATH}events/:eventAlias`, element: <EventsPage /> },
  { path: `${APP_BASE_PATH}my-page`, element: <MyPage /> },
  { path: `${APP_BASE_PATH}join/referral`, element: <JoinReferralPage /> },
  { path: `${APP_BASE_PATH}join/membership/:membershipAlias`, element: <JoinMembershipPage /> },
  { path: `${APP_BASE_PATH}join`, element: <JoinPage /> },

  { path: `${APP_BASE_PATH}login/email`, element: <AppEmailLoginPage /> },
  { path: `${APP_BASE_PATH}my-page/settings`, element: <AccountSettingsPage /> },
  { path: `${APP_BASE_PATH}my-page/app-settings`, element: <AppSettingsPage /> },
  { path: `${APP_BASE_PATH}collection`, element: <CollectionMainPage /> },
  { path: `${APP_BASE_PATH}collection/:collectionId`, element: <CollectionMainPage /> },
  { path: `${APP_BASE_PATH}commerce/collection`, element: <CollectionMainPage /> },
  { path: `${APP_BASE_PATH}class/collection`, element: <CollectionMainPage /> },
  { path: `${APP_BASE_PATH}theme/collection`, element: <ThemeCollectionMainPage /> },
  { path: `${APP_BASE_PATH}product/:productId`, element: <ProductPage /> },
  { path: `${APP_BASE_PATH}my-page/tutor-profile`, element: <AppTutorProfileEditPage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile`, element: <AppUserProfileEditPage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile/password`, element: <EditPasswordPage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile/phone`, element: <EditPhoneNumberPage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile/address`, element: <EditAddressPage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile/category`, element: <EditCategoryPage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile/account`, element: <EditAccountPage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile/name`, element: <EditNamePage /> },
  { path: `${APP_BASE_PATH}my-page/user-profile/child`, element: <EditChildPage /> },

  { path: `${APP_BASE_PATH}tutor-info/:tutorId`, element: <TutorInfoPage /> },
  { path: `${APP_BASE_PATH}partner/:partnerId`, element: <PartnerProfilePage /> },
  { path: `${APP_BASE_PATH}chat`, element: <ChatPage /> },
  { path: `${APP_BASE_PATH}my-page/settlements`, element: <SettlementsPage /> },
  { path: `${APP_BASE_PATH}my-page/favorite`, element: <FavoritePage /> },
  { path: `${APP_BASE_PATH}my-page/favorite/:tab`, element: <FavoritePage /> },
  { path: `${APP_BASE_PATH}my-page/schedule`, element: <SchedulePage /> },
  { path: `${APP_BASE_PATH}my-page/class-management`, element: <ClassManagePage /> },
  { path: `${APP_BASE_PATH}my-page/purchase`, element: <PurchasePage /> },
  { path: `${APP_BASE_PATH}my-page/purchase/refund`, element: <PurchaseRefundPage /> },
  { path: `${APP_BASE_PATH}my-page/purchase/purchase-detail`, element: <PurchaseDetailPage /> },
  { path: `${APP_BASE_PATH}my-page/class-invoices`, element: <InvoiceManagePage /> },
  { path: `${APP_BASE_PATH}my-page/coupon`, element: <UseCouponPage /> },
  { path: `${APP_BASE_PATH}my-page/reward`, element: <RewardPage /> },
  { path: `${APP_BASE_PATH}my-page/point`, element: <UserPointPage /> },
  { path: `${APP_BASE_PATH}my-page/notice`, element: <NoticePage /> },
  { path: `${APP_BASE_PATH}my-page/policy`, element: <PolicyPage /> },
  { path: `${APP_BASE_PATH}notice/:noticeId`, element: <NoticeDetailPage /> },
  { path: `${APP_BASE_PATH}alarm`, element: <AlarmPage /> },
  { path: `${APP_BASE_PATH}class-report/edit`, element: <ClassReportEditPage /> },
  { path: `${APP_BASE_PATH}class-report/view`, element: <ClassReportViewPage /> },
  { path: `${APP_BASE_PATH}programs/*`, element: <RedirectToHome /> },
  { path: '/forbidden', element: <ForbiddenPage /> },
  { path: '*', element: <NotFoundPage /> },
];

export default routes;
